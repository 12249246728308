import { Controller } from "@hotwired/stimulus";
import WaveSurfer from "wavesurfer.js";

export default class extends Controller {
  static targets = ["recordingsList"];

  connect() {
    this.wavesurfers = {};
    this.initializeWavesurfers();
    this.element.addEventListener(
      "recordings:updated",
      this.handleRecordingsUpdated.bind(this)
    );
  }

  handleRecordingsUpdated(event) {
    this.initializeWavesurfers();
  }

  initializeWavesurfers() {
    const waveformContainers =
      this.recordingsListTarget.querySelectorAll("[data-waveform]");
    waveformContainers.forEach((container) => {
      const recordingId = container.dataset.recordingId;
      const audioUrl = container.dataset.audioUrl;
      const playButton = container
        .closest(".recording-item")
        .querySelector(".play-pause");

      if (!audioUrl) {
        return;
      }

      if (this.wavesurfers[recordingId]) {
        this.wavesurfers[recordingId].destroy();
      }

      this.wavesurfers[recordingId] = WaveSurfer.create({
        container: container,
        waveColor: "violet",
        progressColor: "purple",
        responsive: true,
        height: "50",
        barWidth: 2,
        barGap: 1,
        backend: "MediaElement",
      });

      this.wavesurfers[recordingId].load(audioUrl, null, "audio/mp4");

      this.wavesurfers[recordingId].on("ready", () => {
        const duration = this.wavesurfers[recordingId].getDuration();
        const durationElement = document.getElementById(
          "duration-" + recordingId
        );
        durationElement.textContent = this.formatTime(duration);
        if (playButton) {
          playButton.disabled = false;
          playButton.innerHTML = '<i class="fas fa-play"></i>';
        }
      });

      this.wavesurfers[recordingId].on("play", () => {
        if (playButton) {
          playButton.innerHTML = '<i class="fas fa-pause"></i>';
        }
      });

      this.wavesurfers[recordingId].on("pause", () => {
        if (playButton) {
          playButton.textContent = "Play";
        }
      });

      this.wavesurfers[recordingId].on("error", (error) => {
        if (playButton) {
          playButton.disabled = true;
          playButton.textContent = "Error";
        }
      });
    });
  }

  formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    const formattedSeconds =
      remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;

    return `${minutes}:${formattedSeconds}`;
  }

  playPause(event) {
    const waveformContainer = event.currentTarget
      .closest(".recording-item")
      .querySelector("[data-waveform]");
    if (!waveformContainer) {
      return;
    }

    const recordingId = waveformContainer.dataset.recordingId;
    const wavesurfer = this.wavesurfers[recordingId];

    if (wavesurfer) {
      if (wavesurfer.isPlaying()) {
        wavesurfer.pause();
      } else {
        wavesurfer.play();
      }
    }
  }

  recordingsUpdated() {
    // console.log("Recordings updated");
    this.initializeWavesurfers();
  }

  disconnect() {
    Object.values(this.wavesurfers).forEach((wavesurfer) =>
      wavesurfer.destroy()
    );

    this.element.removeEventListener(
      "recordings:updated",
      this.handleRecordingsUpdated
    );
  }
}
